<div class="contactContainer">
    <h2>Contact Us</h2>
    <div>
        <mat-list>
            <mat-list-item><strong>General Inquiries:</strong><span class="tabbedText">admin&#64;paragonai.io</span></mat-list-item>
            <mat-list-item><strong>Investors: </strong><span class="tabbedText">invest&#64;paragonai.io</span></mat-list-item>
            <mat-list-item><strong>Social: </strong><span class="tabbedText">social&#64;paragonai.io</span></mat-list-item>
        </mat-list>
    </div>
</div>
