import { Component } from '@angular/core';

@Component({
  selector: 'app-prometheus',
  standalone: false,
  templateUrl: './prometheus.component.html',
  styleUrl: './prometheus.component.scss'
})
export class PrometheusComponent {

}
