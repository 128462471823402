<div class="pageContainer">
    <div class="headerSection">
        <h1>Modernizing Workflow Automation</h1>
    </div>
    <div class="pageSection">
        <h2>Paragon's Objective</h2>
        <p>In today’s fast-paced world, staying ahead means working smarter, not harder. Paragon is designed to empower you with an intuitive platform that makes monitoring, creating, and executing automated workflows seamless and effortless. With Paragon, 
            you’ll not only save time, but you’ll also unlock new levels of efficiency and productivity, allowing you to focus on what truly matters.
        </p>
    </div>
    <div class="pageSection">
        <h2>Its Three Key Pillars</h2>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
    </div>
</div>
